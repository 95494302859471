<template>
    <div>
      <div style="text-align: center">
        <h3>Tambah Group</h3>
      </div>
      <br />
      <form v-on:submit.prevent="addContact">
        <label>Nama Group*</label>
        <input type="text" class="form-control" v-model="form.name" autofocus required />
        <br />
        <div class="button-group d-flex pt-25">
            <button type="submit" class="btn btn-primary btn-block btn-default btn-squared text-capitalize mx-auto d-block">
              simpan
            </button>
        </div>
      </form>
    </div>
  </template>
  
  <script>
  export default {
    data() {
      return {
        form: {
          name: "",
        },
      };
    },
    mounted() {
  
    },
    methods: {
      async addContact(){
        try{
          let response = await this.$store.dispatch('group_create', this.form)
          this.$toast.success(response.data.message);
          this.$root.$emit("toggle_modal", "new_group")
          this.$root.$emit("refresh_table", true)
        }catch(err){
          this.$toast.error(err.response.data.message);
        }
      },
    },
  };
  </script>
  