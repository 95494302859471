<template>
    <div>
      <div style="text-align: center">
        <h3>Tambah Anggota</h3>
      </div>
      <br />
      <form v-on:submit.prevent="searchNonMember">
        <div class="input-group mb-3">
            <input type="text" v-model="form.search" class="form-control" style="height:2.8rem !important" placeholder="Cari berdasarkan nama atau nomor kontak">
            <div class="input-group-append">
              <button class="btn btn-secondary" type="submit">Cari</button>
            </div>
        </div>
        <br>
      </form>
      <div v-for="contact in list_contact" :key="contact.id">
        <div class="card">
            <div class="card-body">
              {{ contact.name }} - {{ contact.number }}
              <button v-if="!contact.isExist" class="btn btn-success float-right" v-on:click="insertIntoGroup(contact.id)">Tambah</button>
              <button v-else class="btn btn-danger float-right" disabled>Sudah Masuk Dalam Group</button>
            </div>
        </div>
        <br>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    props: ['gid'],
    data() {
      return {
        form: {
            group_id: "",
            search: "",
        },
        list_contact: []
      };
    },
    mounted() {
        this.$props.gid
        this.form.group_id = this.$props.gid
    },
    methods: {
      async insertIntoGroup(id){
            let payload = {
                contact_id: id,
                add: [{"id": parseInt(this.form.group_id)}],
                remove: []
            }
            try{
                let response = await this.$store.dispatch('group_update_by_contact', payload)
                this.$toast.success(response.data.message);
                this.searchNonMember()
                this.$root.$emit("refresh_table", true);
            }catch(err){
                this.$toast.error(err.response.data.message);
            }
      },
      async searchNonMember(){
        try{
          let response = await this.$store.dispatch('group_list_not_member', this.form)
          this.list_contact = response.data.data.contacts
        }catch(err){
          this.$toast.error(err.response.data.message);
        }
      }
    },
  };
  </script>
  