<template>
    <div>
        <div class="contents">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="breadcrumb-main user-member justify-content-sm-between ">
                            <div class=" d-flex flex-wrap justify-content-center breadcrumb-main__wrapper">
                                <div class="d-flex align-items-center user-member__title justify-content-center mr-sm-25">
                                    <h4 class="text-capitalize fw-500 breadcrumb-title">Edit Group Kontak</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <div v-if="!isLoading" class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <form v-on:submit.prevent="updateGroup">
                                <label> ID Group </label>
                                <input type="text" class="form-control" v-model="form.id" disabled>
                                <br>
                                <label> Nama Group </label>
                                <input type="text" class="form-control" v-model="form.name" required>
                                <br>
                                <button class="btn btn-success btn-block" id="simpan_edit" type="submit"> Simpan </button>
                            </form>
                            <hr style="margin-top:20px;margin-bottom:20px">
                            <h2 style="text-align:center">List Anggota</h2>
                            <br>
                            <button type="button" class="btn btn-primary btn-block" v-on:click="toggleModal('new_member')">
                                Tambah Anggota
                            </button>
                            <button type="button" class="btn btn-primary btn-block" v-on:click="toggleModal('new_member_bulk')">
                                Tambah Anggota Bulk
                            </button>
                            <br>
                            <EditGroupTable :gid="group_id"></EditGroupTable>
                        </div>
                        <div v-else class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center>
                                <div class="spinner-border spinner" role="status"></div>
                            </center>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <MoveMember :gid="group_id"></MoveMember>
                    </div>
                    <div class="col-md-6">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center><h3> Export Anggota Group </h3></center>
                            <br>
                            <a v-on:click="exportContact" class="btn btn-secondary btn-block text-white"> Download Excel </a>
                            <download-csv ref="exportNow" :data="exportJson" name="starsender_kontak.csv" style="display: none"></download-csv>

                        </div>
                    </div>
                    <!-- <div class="col-md-12">
                        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
                            <center><h3> Repair Group (BETA) </h3></center>
                            <br>
                            <p> Repair group berfungsi untuk mengahpus kontak yang dobel dalam group </p>
                            <p> Fitur ini masih beta. <b><i>Do With Your Own Risk</i></b> </p>
                            <a v-on:click="repairGroup" class="btn btn-dark btn-block text-white"> Repair </a>
                        </div>
                    </div> -->
                </div>
            </div>
            <modal name="new_member" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddMember :gid="group_id"/>
                </div>
            </modal>
            <modal name="new_member_bulk" :height="'auto'" :adaptive="true" :scrollable="true">
                <div style="margin: 20px">
                    <AddGroupTable :gid="group_id" />
                </div>
            </modal>
        </div>
    </div>
</template>

<script>
import EditGroupTable from "@/components/tables/EditGroup.vue";
import AddGroupTable from "@/components/tables/GroupAdd.vue";
import AddMember from "@/components/forms/group/AddMember.vue";
import MoveMember from "@/components/forms/group/MoveMember.vue";

export default {
    components: {
        EditGroupTable,
        AddMember,
        MoveMember,
        AddGroupTable,
    },
      data() {
        return {
            exportJson: [],
            form: {
                id: "",
                name: "",
            },
            group_id: "",
            isLoading: true
        };
      },
      mounted() {
        this.group_id = this.$route.query.id
        this.loadData()
      },
      methods: {
        async loadData(){
            try{
                let detail = await this.$store.dispatch("group_detail", { id: this.group_id })
                this.form = detail.data.data.group
                this.isLoading = false
            }catch(err){
                this.$toast.error(err.response.data.message)
                this.$router.push("/group")
            }
        },
        async updateGroup(){
            try{
                let response = await this.$store.dispatch('group_update', this.form)
                this.$toast.success(response.data.message);
            }catch(err){
                this.$toast.error(err.response.data.message);
            }
        },
        async exportContact(){
            try{
                this.$toast.info("Mohon Tunggu...");

                await this.$store.dispatch('group_member_export', { group_id: this.group_id })
                .then((response) => {
                    let rawData = response.data.data.contacts
                    let final = []
                    for(let i = 0;i < rawData.length;i++){
                        if(rawData[i].number.startsWith('0')){
                            rawData[i].number = '62' + rawData[i].number.substr(1)
                        }

                        final.push({
                            "Nama": rawData[i].name,
                            "Whatsapp": rawData[i].number,
                        })

                        let vars

                        if(rawData[i].data){
                            vars = JSON.parse(rawData[i].data)
                        }else{
                            vars = []
                        }
                        for(let j = 0;j < 5;j++){
                            final[i]['VARIABEL '+(j+1)] = vars[j]?.value ?? ""
                        }
                    }

                    this.exportJson = final
                })
                .then(() => {
                    this.$refs.exportNow.$el.click()
                })
            }catch(err){
                this.$toast.error(err);
            }
        },
        async repairGroup(){
            try{
                await this.$store.dispatch('group_repair', { group_id: this.group_id })
                .then((response) => {
                    this.$root.$emit("refresh_table", true)
                    this.$toast.success(response.data.message);
                })
            }catch(err){
                this.$toast.error(err);
            }
        },
        toggleModal(param) {      
            this.$modal.toggle(param);
        },
      },
    };
</script>    