<template>
  <div>
    <vue-good-table mode="remote" @on-page-change="onPageChange" @on-sort-change="onSortChange"
      @on-column-filter="onColumnFilter" @on-per-page-change="onPerPageChange" :totalRows="table.total_records"
      :isLoading.sync="table.isLoading" :pagination-options="{
          enabled: true,
          dropdownAllowAll: false,
          perPageDropdown: [10, 25, 50, 100],
        }" :columns="table.columns" :rows="table.rows">
      <template slot="table-row" slot-scope="props">
        <span v-if="props.column.field == 'action'">
          <router-link :to="'/contact/edit?id=' + props.row.id">
            <a class="btn btn-info text-white margin5">
              Edit
            </a>
          </router-link>
          <a v-on:click="deleteMember(props.row.id)" class="btn btn-danger text-white margin5">
            Hapus
          </a>
        </span>
        <span v-else>
          {{ props.formattedRow[props.column.field] }}
        </span>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
  export default {
    props: ['gid'],
    data() {
      return {
        table: {
          total_records: 0,
          isLoading: true,
          columns: [{
              label: "Nama",
              field: "name",
              //   filterOptions: {
              //     enabled: true,
              //   },
              sortable: false,
              width: "40%",
            },
            {
              label: "Nomor Whatsapp",
              field: "number",
              //   filterOptions: {
              //     enabled: true,
              //   },
              sortable: false,
              width: "40%",
            },
            {
              label: "Action",
              field: "action",
              sortable: false,
              width: "20%",
            },
          ],
          rows: [],
          serverParams: {
            columnFilters: {},
            sort: [{
              field: "",
              type: "",
            }, ],
            page: 1,
            limit: 10,
            group_id: this.gid
          },
        },
      };
    },
    mounted() {
      this.fn = () => {
        this.loadItems();
      }
      this.$root.$on("refresh_table", this.fn);
      this.loadItems();
    },
    beforeDestroy() {
      this.$root.$off("refresh_table", this.fn);
    },
    methods: {
      async deleteMember(id) {
        try {
          let response = await this.$store.dispatch("group_member_delete", {
            group_id: this.table.serverParams.group_id,
            id: id
          });
          this.loadItems();
          this.$toast.success(response.data.message);
        } catch (err) {
          this.$toast.error("Server error");
        }
      },
      updateParams(newProps) {
        this.table.serverParams = Object.assign({},
          this.table.serverParams,
          newProps
        );
      },
      onPageChange(params) {
        this.updateParams({
          page: params.currentPage
        });
        this.loadItems();
      },
      onPerPageChange(params) {
        this.updateParams({
          limit: params.currentPerPage
        });
        this.loadItems();
      },
      onSortChange(params) {
        this.updateParams({
          sort: [{
            type: params.sortType,
            field: this.table.columns[params.columnIndex].field,
          }, ],
        });
        this.loadItems();
      },
      onColumnFilter(params) {
        this.updateParams(params);
        this.loadItems();
      },
      async loadItems() {
        this.table.isLoading = true
        try {
          await this.getGID()
          let response = await this.getFromServer(this.table.serverParams);
          this.table.total_records = response.data.data.total_records;
          this.table.rows = response.data.data.members;
        } catch (err) {
          this.$toast.error("Too many request");
        }
        this.table.isLoading = false
      },
      async getFromServer(param) {
        this.table.serverParams.group_id = await this.getGID()
        return await this.$store.dispatch("group_list_member", param);
      },
      getGID() {
        return this.$props.gid
      }
    },
  };
</script>

<style scoped>
  .margin5 {
    margin: 5px;
  }
</style>