<template>
    <div>
        <div class="userDatatable global-shadow border p-30 bg-white radius-xl w-100 mb-30">
            <center><h3> Pindahkan Semua Anggota Ke Group Lain </h3></center>
            <br>
            <form action="" method="post" id="edit">
                <label> Masukkan Kontak Ke Group </label>
                <select v-model="form.target" class="form-control">
                    <option value="" selected disabled>== PILIH GROUP ==</option>
                    <option v-for="option in group_dropdown" :key="option.id" :value="option.id">
                        {{ option.name }} [ID: {{ option.id }}]
                    </option>
                </select>
                <br>
                <button class="btn btn-success btn-block" type="button" v-on:click="moveGroup"> Pindahkan </button>
            </form>                        
        </div>
    </div>
  </template>
  
<script>
    export default {
        props: ['gid'],
        data() {
            return {
                group_dropdown: [],
                form: {
                    current: "",
                    target: "",
                },
            };
        },
        mounted() {
            this.loadGroups()
        },
        methods: {
            async moveGroup() {
                this.form.current = this.$props.gid
                try {
                    let response = await this.$store.dispatch('group_move', this.form)
                    this.$toast.success(response.data.data.message)
                    this.$root.$emit("refresh_table", true)
                } catch (err) {
                    this.$toast.error(err.response.data.message)
                }
            },
            async loadGroups() {
                try {
                    let response = await this.$store.dispatch('group_list_dropdown')
                    this.group_dropdown = await this.removeCurrent(response.data.data.groups)
                } catch (err) {
                    this.$toast.error("Server error")
                }
            },
            removeCurrent(array){
                for(let i = 0;i < array.length;i++){
                    if(this.$props.gid == array[i].id){
                        array.splice(i, 1)
                    }
                }

                return array
            }
        },
    };
</script>